<template>
  <div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar en alarmas ..."
          single-line
          hide-details
          outlined
        >
        </v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table :items="items" :headers="headers" :search="search">
          <template
            v-slot:[`item.id_user_n2`]="{ item }"
            :v-if="item.user.id_usuario"
          >
            {{ item.user.id_usuario }}
          </template>
          <template
            v-slot:[`item.id_user_n2`]="{ item }"
            :v-if="item.user.id_usuario"
          >
            {{ userN2 }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ fecha(item.createdAt) }}
          </template>
          <template v-slot:[`item.opcion`]="{ item }">
            <v-chip
              v-if="item.reboot"
              class="ma-2"
              color="red"
              text-color="white"
              small
              >reboot
            </v-chip>
            <v-chip
              v-if="item.reflash"
              class="ma-2"
              color="red"
              text-color="white"
              small
              >reflash
            </v-chip>
            <v-chip
              v-if="item.fraude"
              class="ma-2"
              color="red"
              text-color="white"
              small
              >fraude
            </v-chip>
            <v-chip
              v-if="!item.dnlk"
              class="ma-2"
              color="red"
              text-color="white"
              small
              >dnlk
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BackendApi from "@/services/backend.service";
// import xlsx from "json-as-xlsx";
import moment from "moment";

export default {
  data() {
    return {
      userN2: this.$cookies.get("user").email,
      search: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Alias", value: "alias" },
        { text: "Id usuario N2", value: "id_user_n2" },
        { text: "Alarmas detectadas", value: "opcion" },
        { text: "Evento", value: "createdAt" },
      ],
      items: [],
    };
  },
  created() {},
  mounted() {
    this.getAlertDevice();
  },
  computed: {},
  methods: {
    fecha(fecha) {
      return moment(fecha).format("DD/MM/YYYY HH:mm:ss");
    },
    getAlertDevice() {
      BackendApi.get("/alertDeviceData/alertDeviceData/")
        .then((response) => {
          if (response.data.success) {
            // this.items = response.data.data;
            response.data.data.forEach((device) => {
              console.log(device);
              device.alertDeviceData.forEach((alert) => {
                alert.user = device.user;
                alert.alias = device.alias;
                alert.external_id = device.device_external_id;
                // alert.opcion = device.user;
                // alert.fecha = device.user;
                this.items.push(alert);
                console.log(this.items);
              });
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
